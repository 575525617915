import React, { useContext, useMemo } from "react";
import { Script } from "gatsby";

import TrackingContext from "../../context/TrackingContext";

const AssertiveYield = (): React.JSX.Element => {
  const { utm_source } = useContext(TrackingContext);

  return (
    <>
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
                    window.assertive = {
                        debug: false, // append the query string 'assertiveYield=debug' or add this local storage entry 'localStorage.setItem("assertiveYield", "debug")' to enable dynamically
                        entityId: "iv4mspvbgi22vHHfb", // your entity id from the dashboard see: https://suite.assertiveyield.com/welcome,
                        analytics: {
                        sampleRate: 1, // 1 = all sessions are tracked, 0.5 = 50% of the sessions are tracked...
                        custom: {
                            // optional dimensions for custom data, they expect null or string
                            layout: null,
                            userState: null,
                            custom_1: null,
                            custom_2: null,
                            custom_3: null,
                            custom_4: null,
                            custom_5: "${utm_source}",
                            custom_9: "gatsby"
                        },
                        integrations: {
                            webAnalytics: true,
                        },
                        logUnfilled: true,
                        },
                        hash: {
                        generator: "server", // 'server' or 'client'
                        key: function () {
                            return ["utm_source=" + "${utm_source}"].join("|");
                        },
                        values: 5000,
                        },
                        storage: {
                        general: "cookie", // or 'cookie'
                        utm: "cookie", // or 'cookie'
                        //bids: 'localStorage', // or 'cookie'
                        session: {
                            timeout: 120, // minutes of user inactivity after which a session is closed (default 30)
                        },
                        },
                        acquisition: {
                        enabled: true, // defaults to false
                        costParams: ['utm_cost'],
                        currency: 'USD', // default USD
                        clickIdParams: undefined,
                        },
                    };
            `,
        }}
      />
      <Script
        id="yeild-intelligence"
        type="text/javascript"
        src="https://iv4mspvbgi22vHHfb.ay.delivery/client-v2.js"
      />
    </>
  );
};

export default AssertiveYield;
